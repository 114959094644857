





















import { Component, Vue } from 'vue-property-decorator';
import CryptoJS from 'crypto-js';

@Component({
  components: {}
})
export default class Login extends Vue {
  password = '';
  error = false;
  show = false;

  verifyInput(): void {
    this.error = false;
    let hash = CryptoJS.MD5(this.password);
    let hex = hash.toString(CryptoJS.enc.Hex);
    if (hex.toUpperCase() === '8ED1B2A599BAF7721B54F944694C2A77') {
      let timestamp = String(Date.now());
      if (this.$store.dispatch('loginSuccessful', timestamp)) {
        this.$router.push('/');
      }
      // there should be an else with error handling, perhaps a setTimeout of 5s first
    } else {
      this.error = true;
    }
  }
}
